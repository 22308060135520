import React from 'react'
import '../aside_menu/asideMenu.css'
import { FaHome } from 'react-icons/fa'
import { HiInformationCircle } from 'react-icons/hi'
import { GiNewspaper } from 'react-icons/gi'
import { TbReportAnalytics } from 'react-icons/tb'
import { AiFillAlert } from 'react-icons/ai'
import { GiFamilyHouse } from 'react-icons/gi'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { NavLink } from 'react-router-dom'


export default function AsideMenu({ menuStatus, showMenu }) {

    const menuBlock = React.useRef()

    React.useEffect(() => {

        if (window.innerWidth < 751) {
            if (menuStatus) {
                menuBlock.current.classList.add('menu__show');
                document.addEventListener('click', handle);
            }
            else {
                menuBlock.current.classList.remove('menu__show');
                document.removeEventListener('click', handle)
            }
        }
        function handle(e) {
            if (!e.target.closest('.content_menu' || '.menu_extend') && !e.target.closest('.menu_burger') && menuBlock.current.classList.contains('menu__show')) {
                showMenu();
                menuBlock.current.classList.remove('menu__show');
                document.removeEventListener('click', handle);

            }
            if (e.target.closest('.menu_item') && menuBlock.current.classList.contains('menu__show')) {
                showMenu();
                menuBlock.current.classList.remove('menu__show');
                document.removeEventListener('click', handle);
            }
        }
    }, [menuStatus, showMenu])



    const housesExtendMenu = [
        <NavLink to='/incontrol'><MenuItem text={'В управлении'} /></NavLink>,
        <NavLink to='/outcontrol'><MenuItem text={'Вышедшие из управления'} /></NavLink>
    ]
    return (
        <nav className={`content_menu`} ref={menuBlock}>
            <NavLink to='/'> <MenuItem img={<FaHome />} text='Главная' /></NavLink>
            <NavLink to='/news'><MenuItem img={<GiNewspaper />} text='Новости' /></NavLink>
            <NavLink to='/about'><MenuItem img={<HiInformationCircle />} text='О компании' /></NavLink>
            <NavLink to='/report'><MenuItem img={<TbReportAnalytics />} text='Отчетность' /></NavLink>
            <NavLink to='/violence'><MenuItem img={<AiFillAlert />} text='Нарушения' /></NavLink>
            <MenuItemExtend img={<GiFamilyHouse />} text='Дома' menuList={housesExtendMenu} />
        </nav>
    )

}

function MenuItem({ img, text }) {
    return (
        <div className={`menu_item`} >
            {img}
            <div className='item_inner'>{text}</div>
        </div>
    )
}
function MenuItemExtend({ img, text, menuList }) {
    const menuElements = menuList.map((item, index) => {
        return <div key={index}>{item}</div>
    })
    return (
        <div className="menu_item menu_extend">
            <div className='left_side'>
                {img}
                <div className='item_inner'>{text}</div>
            </div>
            <MdOutlineKeyboardArrowRight className='right_arrow' />
            <div className="content_menu menu_extend_second">
                {menuElements}
            </div>
        </div>
    )
}



