import './App.css';
import IndexPage from './components/content/all_content/content_blocks/IndexPage';
import NewsPage from './components/content/all_content/content_blocks/NewsPage';
import AboutPage from './components/content/all_content/content_blocks/AboutPage';
import ReportPage from './components/content/all_content/content_blocks/ReportPage';
import ViolencePage from './components/content/all_content/content_blocks/ViolencePage';
import NotFoundPage from "./components/content/all_content/content_blocks/NotFoundPage";
import InControl from './components/content/all_content/content_blocks/houses/InControl';
import OutControl from './components/content/all_content/content_blocks/houses/OutControl';
import { Routes, Route } from 'react-router-dom'
import Layout from './components/content/all_content/Layout';

function App() {

  const files = [
    { docTitle: 'Договор управления многоквартирным домом по адресу: г. Нижний Новгород, ул. Карьерная, 1а', fileName: 'Договор управления Карьерная, 1а', fileLink: 'downloads/cariernaja1a.pdf' },
    { docTitle: 'Протокол общего собрания собственников № 1 от 14.07.2021 г.  многоквартирного дома по адресу: г. Нижний Новгород, ул. Карьерная, 1а', fileName: 'Протокол 1 от 14.07.2021 г. Карьерная, 1а.', fileLink: 'downloads/asd.docx' }
  ]

  return (
    <>
      <Routes>
        <Route path ='/' element={<Layout />}>
          <Route index element={<IndexPage files={files} />} />
          <Route path='news' element={<NewsPage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='report' element={<ReportPage />} />
          <Route path='violence' element={<ViolencePage />} />
          <Route path='incontrol' element={<InControl />} />
          <Route path='outcontrol' element={<OutControl />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}
export default App;
